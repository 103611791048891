:root {
  --color-primary: #00C9CC;
  --color-secondary: #008A91;
  --color-tertiary: #E9FFFF;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#linkMenu {
  border: none;
  color: #01b9bc;
}

#linkMenu:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

#linkProfile {
  color: #01b9bc;
}

@media screen and (max-width: 1000px) {
  #sidebar {
    display: none;
  }

  #contentProfile {
    margin: auto;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* BOTON ALERT ACEPTAR */
.custom-button-confirm {
  background-color: var(--color-primary);
  color: #FFF;
  margin: 0.2em;
  border-radius: 50px;
  text-transform: none;
  font-size: 16px;
  padding: 8px 30px;
  border: none;
}

.custom-button-confirm:hover {
  /* background-color: var(--color-secondary); */
  filter: brightness(0.8);
}

/* BOTON ALERT CANCELAR */
.custom-button-cancel {
  background-color: #fff;
  color: var(--color-primary);;
  margin: 0.2em;
  border-radius: 50px;
  text-transform: none;
  font-size: 16px;
  padding: 8px 30px;
  border: 1px solid var(--color-primary);;
}

.custom-button-cancel:hover {
  filter: brightness(0.8);
}

/* BOTON ALERT ATRAS */
.custom-button-back {
  background-color: var(--color-secondary);
  color: #FFF;
  margin: 0.2em;
  border-radius: 50px;
  text-transform: none;
  font-size: 16px;
  padding: 8px 30px;
  border: none;
}

.custom-button-back:hover {
  filter: brightness(0.8);
}
